<template>
  <div class="guidePage">
    <div class="guide-top"></div>
    <div class="guide-bottom"></div>
    <!-- <div class="djs"><div>3</div></div> -->
    <div class="djs">
      <svg class="ring-container" :style="`width: ${size}; height: ${size}`">
        <circle
          :cx="cx"
          :cy="cx"
          :r="r"
          :stroke="backgroundColor"
          :stroke-width="width"
          stroke-linecap="round"
          fill="none"
        ></circle>
        <circle
          :cx="cx"
          :cy="cx"
          :r="r"
          class="ring"
          :stroke-width="width"
          :stroke="color"
          :stroke-dasharray="`${circum}`"
          stroke-linecap="round"
          fill="none"
        >
          <animate
            attributeName="stroke-dashoffset"
            :from="circum"
            :to="endCircum"
            :dur="originCountDown"
          />
        </circle>
        <text
          :x="cx + 5"
          :y="cx + 5"
          text-anchor="end"
          :font-size="fontSize + 5"
          :fill="fontColor"
        >
          {{ countDown }}
        </text>
        <text
          :x="cx + 5"
          :y="cx + 5"
          text-anchor="start"
          :font-size="fontSize - 5"
          :fill="fontColor"
        >
        </text>
      </svg>
    </div>
    <img src="~@/assets/img/guide-logo.png" class="guide-logo" />
    <div class="joinBtn"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      endCircum: 0,
      countDown: this.originCountDown,
    };
  },
  computed: {
    cx() {
      // 中心点位置
      return this.size / 2;
    },
    r() {
      // 半径
      return this.size / 2 - this.width;
    },
    circum() {
      return parseInt(this.r * Math.PI * 2);
    },
  },
  props: {
    fontSize: {
      type: Number,
      default: 12,
    },
    size: {
      type: Number,
      default: 50,
    },
    width: {
      type: Number,
      default: 3,
    },
    backgroundColor: {
      type: String,
      default: "#f0f0f0",
    },
    color: {
      type: String,
      default: "#F65102",
    },
    fontColor: {
      type: String,
      default: "#333",
    },
    originCountDown: {
      type: Number,
      default: 3,
    },
  },
  mounted() {
    this.interval = setInterval(() => {
      let diff = "1";
      this.countDown -= diff;
      if (this.countDown <= 0) {
        clearInterval(this.interval);
      }
    }, 1000);
  },
};
</script>
<style lang="less" scoped>
.guidePage {
  height: 100vh;
  background: #fff;
  position: relative;
  .guide-top {
    width: 100%;
    height: 5.4rem;
    background: url(~@/assets/img/guide-top.png) no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .guide-bottom {
    width: 100%;
    height: 4rem;
    background: url(~@/assets/img/guide-bottom.png) no-repeat;
    background-size: 100% 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .guide-logo {
    width: 3.18rem;
    height: 2.42rem;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .djs {
    // border:2px solid rgba(178, 178, 178, .4);
    // border-radius: 50%;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // position: relative;
    div {
      width: 0.6rem;
      height: 0.6rem;
      line-height: 0.6rem;
      background: rgba(178, 178, 178, 0.4);
      border-radius: 50%;
      border: 2px solid #fff;
      font-size: 0.26rem;
      color: #fff;
      text-align: center;
    }
  }
  .joinBtn {
    width: 2.6rem;
    height: 0.65rem;
    background: url(~@/assets/img/guide-btn.png) no-repeat;
    background-size: 100% 100%;
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .ring-container {
    .ring {
      transform: rotate(-90deg);
      transform-origin: 50% 50%;
    }
  }
}
</style>